<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <base-card>
          <template #header>يرجى الإنتباه</template>
          <template #body>
            <h5>
              تم إيقاف حسابك, يترتب عليك مزاد او اكثر لم يتم دفعها
            </h5>
            <h6>
              للإستفسار يرجى&nbsp;<router-link :to="{ name: 'contact' }"
                >التواصل</router-link
              >&nbsp;مع الإدارة
            </h6>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";

export default {
  components: { BaseCard },
};
</script>
